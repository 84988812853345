@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.hero {
  background-color: #a2886a;
  background-image: url(/images/hero-image-xl.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

@media screen and (max-width: 768px) {
  .hero {
    background-image: url(/images/hero-image-mobile-xl.jpg);
  }
}


.button {
  border-radius: 30px;
  border: 2px solid #fff52c;
  color: #fff52c;
  display: inline-block;
  font-family: 'Untitled', Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  min-width: 158px;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {
  .button-sm {
    font-size: 14px;
    min-width: 112px;
    padding: 4px;
  }
}

.button:hover {
  background-color: #fff52c;
  border-color: #fff52c;
  color: #938369;
}

.button-dark {
  color: #000;
  border-color: #000;
}

.button-dark:hover {
  color: #000;
  border-color: #000;
}

.button-small {
  font-size: 14px;
  font-weight: 600;
  min-height: 46px;
}

.input {
  background: transparent;
  border-radius: 30px;
  border: 2px solid #000;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  min-height: 46px;
  padding: 8px 24px;
}

.input:focus {
  border-color: #000;
  outline: none;
}

.input::placeholder {
  color: #938369;
}

.hover-pause:hover > * {
  animation-play-state: paused;
}

body {
  font-family: 'Untitled', Arial, sans-serif
}

@font-face {
  font-family: 'Untitled';
  src: url('/fonts/untitled-sans-web-re.woff') format('woff');
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'Untitled';
  src: url('/fonts/untitled-sans-web-re.woff') format('woff');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Untitled';
  src: url('/fonts/untitled-sans-web-me.woff') format('woff');
  font-weight: 500;
  font-style: normal
}

input:checked+div {
  @apply border-black;
}

input:checked+div svg {
  @apply block;
}